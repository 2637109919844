//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
//2.使用路由
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  // mode:'history' 
  //4.配置路由的path和组件
  routes: [
    {
      path: "/",
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      mata: {
        title: '合木智构首页'
      }
    },
    {
      path: "/auto-explain",
      name: 'auto-explain',
      component: () => import('../views/AutoExplain.vue'),
      mata: {
        title: '自动解译'
      }
    },
    {
      path: "/usage-prediction",
      name: 'usage-prediction',
      component: () => import('../views/UsagePrediction.vue'),
      mata: {
        title: '材料用量预测'
      }
    },
    {
      path: "/about",
      name: 'about',
      component: () => import('../views/AboutView.vue'),
      mata: {
        title: '关于合木智构'
      }
    },
    {
      path: "/login",
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: "/ShearWallCases",
      name: 'ShearWallCases',
      component: () => import('../views/ShearWallCasesView.vue')
    },
    {
      path: "/lowgai",
      name: 'lowgai',
      component: () => import('../views/LouGaiCasesView.vue')
    },
    {
      path: "/core",
      name: 'core',
      component: () => import('../views/CoreBarrelCasesView.vue')
    },
    {
      path: "/IntelligentDesign",
      name: 'IntelligentDesign',
      component: () => import('../views/IntelligentDesign.vue')
    },
    {
      path: "/HemuNews",
      name: 'HemuNews',
      component: () => import('../views/HemuNews.vue')
    },
    {
      path: "/DevelopProducts",
      name: 'DevelopProducts',
      component: () => import('../views/DevelopProducts.vue')
    },
    {
      path: "/projects",
      component: () => import('../views/ProjectView.vue'),
      redirect: "/projects/shearwall",
      children: [
        {
          name: 'shearwall',
          path: "shearwall",
          component: () => import('../views/ShearWallProject.vue'),
        },
        {
          path: "beam",
          component: () => import('../views/BeamProject.vue')
        },
        {
          path: "core",
          component: () => import('../views/CoreProject.vue')
        },
        {
          path: 'shearwall-cases',
          component: () => import('../views/ShearWallCases.vue'),
        },
        {
          path: '/projects/beam-cases',
          component: () => import('../views/BeamCases.vue')
        },
        {
          path: '/projects/core-cases',
          component: () => import('../views/CoreCases.vue')
        },
        {
          path: '/projects/ganio',
          component: () => import('../views/GAINO.vue')
        }
      ]
    },
    {
      path: "/shearwall-item",
      name: 'shearwall-item',
      component: () => import('../views/ShearWallItem.vue')
    },
    {
      path: "/shearwall-item-biad",
      name: 'shearwall-item-biad',
      component: () => import('../views/ShearWallItemBiad.vue')
    },
    {
      path: "/beam-item",
      name: 'beam-item',
      component: () => import('../views/BeamItem.vue')
    },
    {
      path: "/core-item",
      name: 'core-item',
      component: () => import('../views/CoreItem.vue')
    },
    {
      path: "/shop",
      component: () => import('../views/ShopView.vue'),
      redirect: ('/shop/shearwall'),
      children: [
        {
          path: "shearwall",
          component: () => import('../views/ShearWallProduct.vue')
        },
        {
          path: "beam",
          component: () => import('../views/BeamProduct.vue')
        },
        {
          path: "core",
          component: () => import('../views/CoreProduct.vue')
        },
        {
          path: "order",
          component: () => import('../views/OrderView.vue')
        },
      ]
    },
    {
      path: "/item",
      component: () => import('../views/ShearWallItem.vue'),
    },
    {
      path: "/setting",
      component: () => import('../views/SettingView.vue')
    },
    {
      path: "/admin",
      component: () => import('../views/AdministratorsData.vue'),
      redirect:"/admin/data",
      children: [
        {
          path: "data",
          component: () => import('../views/AdminData.vue'),
          name:'数据分析'
        },
        {
          name: 'projectList',
          path: "projectList",
          component: () => import('../views/AdminProjectListView.vue'),
        },
        {
          path: "user",
          component: () => import('../views/AdminUser.vue'),
          name:'用户列表'
        },
        {
          path: "allProjectList",
          component: () => import('../views/ProjectList.vue'),
          name:'项目总览'
        },
        {
          path: "log",
          component: () => import('../views/AdminLog.vue'),
          name:'操作日志'
        },
        {
          path: "update",
          component: () => import('../views/AdminUpdate.vue'),
          name:'版本更新'
        },
        {
          path: "production",
          component: () => import('../views/AdminProducts.vue'),
          name:'管理商品信息'
        },
        {
          path: "message",
          component: () => import('../views/AdminMessage.vue'),
          name:'群发通知'
        },
        {
          path: "historyNews",
          component: () => import('../views/AdminHistoryNews.vue'),
          name:'历史消息'
        },
        {
          path: "version",
          component: () => import('../views/AdminVersion.vue'),
          name:'版本管理'
        },
        {
          path: "addVersion",
          component: () => import('../views/AdminAddVersion.vue'),
          name:'新增版本'
        },
        {
          path: "addNews",
          component: () => import('../views/AdminAddNews.vue'),
          name:'新增资讯'
        },
        {
          path: "allNews",
          component: () => import('../views/AdminAllNews.vue'),
          name:'查看历史资讯'
        },
      ]
    },

  ]
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) { //拦截后设置标题
    document.title = to.meta.title
  }
  next()
})
//5.导入路由实例
export default router