<template>
  <div id="app" >
    <router-view v-if="show"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return { show: true };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },

  methods: {
    // 高阶组件定义刷新方法
    reload() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.line {
  width: 1200px;
  height: 2px;
  background-color: #ccc;
  margin: 50px auto;
}
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
a:hover {
  color: #a381ba;
}
</style>
