export default {
    'intel_product': '智能设计产品',
    'developing_products': '开发测试中产品',
    'typical_cases': '典型案例集',
    'HemuNews': '合木资讯',
    'about_hemu': '关于合木',
    'about_us': '关于合木',
    'language': '语言',
    'sign_up': '注册/登录',
    'shearwall_structure': '剪力墙结构',
    'frame_structure': '框架结构',
    'core_structure': '框架 - 核心筒结构',
    'structure_intel': '建筑结构设计智能化',
    'watch_video': '观看视频',
    'production_and_solution': '产品与解决方案',
    'local_assistant': 'Copilot-local 本地版智能设计助手',
    'local_assistant_content': 'AIstructure-Copilot为一款基于CAD平台的建筑结构智能设计助手',
    'platform_cloud': 'Intelligent Design智能设计项目管理平台',
    'platform_cloud_content': 'AIstructure-Platform是基于网页的项目智能设计与管理平台',
    'shearwall_structure_design_title': '剪力墙结构布置智能设计',
    'shearwall_structure_design_content': '基于生成对抗网络、图生成网络等生成式AI技术，通过输入建筑平面布置图，AI自动生成对应剪力墙结构布置设计图',
    'frame_structure_design_title': '框架结构布置智能设计',
    'look_forward': '敬请期待',
    'core_structure_design_title': '框架-核心筒结构截面智能设计',
    'core_structure_desgin_content': '基于生成对抗网络等生成式AI技术，通过输入框架-核心筒结构的平面布置图，AI自动生成对应的结构构件截面尺寸设计',
    'key_component': '核心能力',
    'key_component_content1': '“数据-力学-知识”耦合',
    'key_component_content2': '驱动的智能生成式设计算法',
    'patent': '发明专利',
    'news': '资讯动态',
    'more_news': '查看更多资讯',
    'known_more': '了解更多',
    'product_problem': '产品问题',
    'contact_email': '联系邮箱：',
    'cooperate': '合作',
    'communication_group': 'AI-structure交流群',
    'QQ_group': 'QQ群：',
    'case_number': '备案号：',
    'suggest_chrome': '建议您使用Chrome浏览器浏览本网站以获得更好的体验',
    'product_download': '产品下载',
    'intel_product_content1': 'AIstructure-Copilot为一款基于CAD平台的建筑结构智能设计助手。',
    'intel_product_content2': '在本地计算机安装AIstructure.exe程序，程序自动根据本地安装的CAD平台（目前推荐Autodesk CAD以及天正CAD）创建其Copilot。基于AIstructure-Copilot，便可在本地电脑的CAD平台中实现剪力墙结构的智能设计。',
    'intel_platform': '智能设计项目管理',
    'intel_platform_tips': '(支持典型案例试用，中英文)',
    'sign_in': '登录',
    'instruction': '使用说明',
    'intel_platform_content1': '智能设计项目管理平台具有剪力墙构件设计，梁-板构件设计，框架-核心筒构件设计，将上传的数据以及建筑像素图经过智能加工处理，生成结构像素图并导入到原始建筑CAD中。',
    'intel_platform_content2': '目前剪力墙构件设计推荐使用，梁-板构件设计、框架-核心筒构件设计模块正在改进调试中，欢迎试用。',
    'automated_rule_interpretation': '规则自动解译',
    'automated_rule_interpretation_content1': '如果能够让计算机读懂土木工程领域设计规范并自动将其翻译成计算机代码，那将显著提升我们的结构设计智能化水平。',
    'automated_rule_interpretation_content2': '对此，开发了AI规则自动解译模块。用户输入自然语言表达的语句，则AI可以通过识别词语含义，对自然语言的文本打上标签，进而把文本内容翻译成对应的语法树结构，可以转换成任意计算机语言的代码。',
    'enter_automated': '进入规则自动解译界面',
    'input': '输入',
    'interpretation': '解译',
    'input_example': '可以试试输入以下规则示例：',
    'contact_person': '联系人：',
    'case_intro': '案例简介',
    'input_pixel_diagram': '输入像素图',
    'click_to_download_data': '点击下载矢量数据',
    'engineer_design_pixel_diagram': '工程师设计像素图',
    'StructGAN_design_pixel_diagram': 'StructGAN设计像素图',
    'StructGAN_design_model': 'StructGAN设计计算模型',
    'click_download': '点击下载',
    'download': '下载',
    'input_data': '输入数据',
    'engineer_design': '工程师设计',
    'StruGAN_design': 'StruGAN设计',
    'company_intro': '公司简介',
    'company_intro_content1': '合木智构成立于2022年3月，专注于改善建造业低效现状，赋能土木工程数字化与智能化。',
    'company_intro_content2': '合木智构自主研发了基于设计图纸、设计条件、设计规范、结构力学等Data-Driven和AI for Science的智能化设计关键技术，并获得了覆盖各关键设计要素的系列专利，形成了具备经验学习与力学分析能力的建筑结构生成式智能设计产品。该技术能自动完成符合复杂标准规范要求的结构方案设计任务，且AI设计的材料用量与工程师设计相近，并显著缩短建筑结构设计时间10倍以上、减少工程师工作量50%以上。',
    'company_intro_content3': '未来将为建筑工程，及新基建、交通、能源建设带来全新的设计范式和生产模式。',
    'hemu_team': '合木团队',
    'hemu_team_content': '结构设计+AI赋能。由清华土木系教授领衔，联合建筑领域知名专家共同创立，形成顶尖科研团队+资深业界团队。',
    'history': '发展历史',
    'awards': '荣誉资质',
    'basic_info': '填写基本资料',
    'back_top': '回到顶部',
    'contact_now': '立即咨询',
    'name': '姓名',
    'input_name': '请输入姓名',
    'unit': '单位',
    'input_unit': '请输入单位',
    'email': '邮箱',
    'input_email': '请输入邮箱',
    'contact_phone': '联系电话',
    'input_phone': '请输入手机号码',
    'question_detail': '问题详情',
    'input_question_detail': '请输入问题详情',
    'other_suggestion': '其他问题或改进建议',
    'input_suggestion': '请输入建议',
    'submit': '提交',
    'reset': '重置',
    'product_please_contact': '产品中使用问题，请联系：',
    'hemu_please_contact': '对合木产品/解决方案感兴趣，或有意向进行其他合作，请联系：',
    'name_length_limit': '长度在 2 到 5 个字符',
    'incorrect_email': '邮箱的格式不正确',
    'incorrect_phone': '手机号码的格式不正确',
    'initial_page': '首页',
    'main_business': '主营业务',
    'business_content': '合木智构为2022年3月成立的一家科技企业，面向土木工程相关领域，为行业提供数字化、智能化等相关服务。目前，已有智能化设计产品于2023年7月在官网发布，并已有700多用户注册使用，完成剪力墙住宅设计1800+例，公司可提供如下服务：',
    'business_content1': '1、个性化产品部署（可提供私有云部署、本地服务器部署服务）',
    'business_content2': '2、定制化研发（可根据用户需求，做有针对的开发工作，包括但不限于相关交叉领域的生成式研发、个性化数据训练等服务）',
    'business_content3': '3、联合开发（可与上下游各产业端相互打通，合作共赢）',
    'quality_and_award': '资质&获奖',
    'quality': '创新型中小企业、北京市科技型中小企业',
    'coopetration': '商务合作',
    'wechat': '（微信同号）',
    'research_process': '研发进程',
    // 登录/注册页面
    'back_to_main': '返回官网',
    'system_update_tips': '由于系统更新，2024年3月29日之前注册的用户，请尽快在登录系统的"忘记密码"中修改密码。',
    'hello': '您好',
    'please_sign_in': '请登录',
    'click_to_sign_up': '还没有账号 点击注册',
    'phone_number': '手机号码',
    'password': '密码',
    'forget_password': '忘记密码',
    'sign_in_now': '立即登录',
    'sign_in_by_code': '验证码登录',
    'please_sign_up': '欢迎注册',
    'account_name': '用户名 (英文) :',
    'enter_english_account_name': '请输入英文名',
    'chinese_name': '姓名 (中文) :',
    'enter_chinese_name': '请输入中文名',
    'unit_name': '单位名称 :',
    'enter_unit_name': '请输入单位名称',
    'enter_phone_number': '请输入手机号码',
    'enter_password': '请输入密码',
    'confirm_password': '确认密码 :',
    'enter_password_again': '请再次输入密码',
    'sex': '性别 :',
    'male': '男',
    'female': '女',
    'phone_code': '手机验证码 :',
    'code': '验证码',
    'get_code': '获取验证码',
    'sign_up_now': '立即注册',
    'account_sign_in_now': '已有账号，立即登录',
    'reset_password': '重设密码',
    'enter_6_code': '请输入6位验证码',
    'change_now': '立即修改',
    'back_to_sign_in': '返回登录',
    'sign_in_by_password': '账号密码登录',
    'enter_correct_phone_number': '请输入正确的手机号格式',
    'correct_password_format': '密码必须是6-15的非空字符',
    'correct_code_format': '验证码为6位字符',
    'correct_chinese_name_format': '用户名必须是2-8位的汉字',
    'correct_english_name_format': '长度为3-11，只能包含数字或英文，至少包含一个英文字母',
    'choose_sex': '请选择性别',
    'sign_in_success': '注册成功, 即将跳转到登录页面，请重新登录！',
    'uncorrect_code': '验证码不正确或过期！',
    'update_success': '修改成功，请重新登录！',
    'login_failed_tips': '用户、手机号或密码验证失败，请重新输入或联系管理员（QQ群：741840451）',
    'login_success_tips': '登录成功',
    'sign_in_by_phone': '手机注册',
    'sign_in_by_email': '邮箱注册',
    'email_code': '邮箱验证码',
    'login_phone': '手机登录',
    'login_email': '邮箱登录',
    'input_correct_email_format': '请输入正确的邮箱格式',
    'password_login': '密码登录',
    'login_number': '用户名/手机号码/邮箱',
    'email_code_login': '邮箱验证码登录',
    'phone_code_login': '手机验证码登录',
    'reset_phone_password': '修改手机密码',
    'reset_email_password': '修改邮箱密码',

    // 云平台页面
    'intelli_design':'智能设计',
    'change_password': '修改密码',
    'admin_page':'管理员界面',
    'log_out': '退出登录',
    'my_project': '我的项目',
    'shearwall_design': '剪力墙设计',
    'frame_design': '框架结构设计',
    'frame_core_desgin': '框架 - 核心筒设计',
    'cad_plugin': 'CAD前后处理插件',
    'tool_download': '参数化建模工具下载',

    // 新建剪力墙项目
    'enter_project_title': '请输入项目标题',
    'shearwall_project': '剪力墙项目',
    'import_project': '导入项目',
    'creat_project': '新建项目',
    'delete': '删除',
    'add_shearwall_design': '添加剪力墙设计',
    'add_shearwall_tips': '（请到典型案例中下载”输入建筑数据(.json)“试用）',
    'project_name': '项目名称',
    'vector_data': '矢量数据',
    'click_upload': '点击上传',
    'component_img': '建筑构件图',
    'space_img': '建筑空间图',
    'cancel': '取消',
    'confirm': '确定',
    'correct_project_title_format': '项目名称必须是英文字母、数字或者下划线',
    'choose_component_img': '请选择建筑构件图',
    'choose_space_img': '请选择建筑空间图',
    'choose_json_file': '请选择json格式的文件',

    // 剪力墙项目详情
    'status': '状态',
    'designed': '已设计',
    'undesigned': '未设计',
    'input_archi': '输入建筑构件图',
    'design_button_tips': '点击设计按钮可获得输出设计',
    'design': '设计',
    'shearwall_pic_instruct':'剪力墙-梁结构设计（红色轴线为剪力墙、蓝色轴线为梁）',
    'diffusion_model': '扩散模型设计结构图像',
    'to_be_designed': '待设计',
    'material_predict': '材料用量智能预测',
    'GAN_pic': 'GAN设计结构图像',
    'GNN1_pic': 'GNN1设计结构图像',
    'GNN2_pic': 'GNN2设计结构图像',
    'remind': '提示',
    'delete_confirm_tips': '此操作将永久删除该项目, 是否继续?',
    'delete_success': '删除成功！',
    'delete_failed': '删除失败!',
    'delete_canceled': '已取消删除',

    'software_copyrights': '获得9项软件著作权',

    'AIstructure-Copilot': 'AIstructure-Copilot',
    'AIstructure-Copilot_tips': '(本地版CAD插件，中英文)',
          'case1_title': "案例一简介：",
          'case1_tips': "建筑结构高度75m，层高3m，共25层，抗震设防烈度8度（0.20g），场地特征周期0.4s。",
          'case2_title': "案例二简介：",
          'case2_tips': "建筑结构高度81m，层高3m，共27层，抗震设防烈度7度（0.10g），场地特征周期0.45s。",
          'case3_title': "案例三简介：",
          'case3_tips': "建筑结构高度54m，层高3m，共18层，抗震设防烈度7度（0.10g），场地特征周期0.35s。",
          'case4_title': "案例四简介：",
          'case4_tips': "建筑结构高度54m，层高3m，共18层，抗震设防烈度6度（0.05g），场地特征周期0.45s。",
          'case5_title': "案例五简介：",
          'case5_tips': "建筑结构高度78m，层高3m，共26层，抗震设防烈度7度（0.10g），场地特征周期0.4s。",
          'case6_title': "案例六简介：",
          'case6_tips': "建筑结构高度54m，层高3m，共18层，抗震设防烈度6度（0.05g），场地特征周期0.45s。",
          'case7_title': "案例七简介：",
          'case7_tips': "建筑结构高度54m，层高3m，共18层，抗震设防烈度6度（0.05g），场地特征周期0.45s。",
          'case8_title': "案例八简介：",
          'case8_tips': "建筑结构高度99m，层高3m，共33层，抗震设防烈度7度（0.10g），场地特征周期0.35s。",
          'case9_title': "案例九简介：",
          'case9_tips': "建筑结构高度51m，层高3m，共17层，抗震设防烈度7度（0.10g），场地特征周期0.4s。",
          'case10_title': "案例十简介：",
          'case10_tips': "建筑结构高度51m，层高3m，共17层，抗震设防烈度7度（0.10g），场地特征周期0.4s。",

          'downloadJOSN': '下载json数据',
          'case_legend': '*图示说明: 灰色轴线为建筑墙，绿色轴线为门窗，红色轴线为剪力墙，蓝色轴线为梁',
          'archi_floor':'建筑标准层',
          'GAN_floor': 'GAN模型结构标准层',
          'GNN1_floor': 'GNN1模型结构标准层',
          'GNN2_floor': 'GNN2模型结构标准层',
          'Diffusion_floor': '扩散模型结构标准层',

          'enter_cloud': '进入智能设计平台'

}