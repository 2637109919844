import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en';
import zh from './lang/zh';
import store from '@/store';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang


Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: store.state.language,
  messages: {
    en: {
        ...en,
        ...elementEnLocale
    },
    zh: {
        ...zh,
        ...elementZhLocale
    }
  }
})

export default i18n