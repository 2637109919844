// request.js
import axios from "axios";
import vue from "@/main";

export let baseURL1 = process.env.VUE_APP_BASE_URL_1
export let baseURL2 = process.env.VUE_APP_BASE_URL_2


export function request(config) {
    const instance = axios.create({
        baseURL: baseURL1,//代理服务器地址
    })
    //请求拦截
    instance.interceptors.request.use(config => {
        const token = sessionStorage.getItem('token');
        // 如果有token的话，让每一个请求携带token
        if (token) {
            config.headers['Authorization'] = token //让每一个请求携带token
        }
        config.headers['language']=JSON.parse(localStorage.getItem('lang'))
        return config
    }, err => {
        console.log(err, 'err');
    })
    //响应拦截
    instance.interceptors.response.use(response => {
        if (response.status == 401) {
            vue.$message.error("用户授权已过期，请重新登录！");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            vue.$router.push("/");
            return
          }
        return response.data
    }, err => {
        console.log(err, 'err');
    })

    return instance(config)
}

export function request2(config) {
    const instance = axios.create({
        baseURL: baseURL2,//代理服务器地址
    })
    //请求拦截
    instance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        // 如果有token的话，让每一个请求携带token
        if (token) {
            config.headers['Authorization'] = token //让每一个请求携带token
        }
        config.headers['language']=JSON.parse(localStorage.getItem('lang'))
        return config
    }, err => {
        console.log(err, 'err');
    })
    //响应拦截
    instance.interceptors.response.use(response => {
        // response.data = JSON.parse(response.request.responseText);
        if (response.status == 401) {
            vue.$message.error("用户授权已过期，请重新登录！");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            vue.$router.push("/");
            return
          }
        return response.data
    }, err => {
        console.log(err, 'err');
    })

    return instance(config)
}
