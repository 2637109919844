// 注册vuex
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
import {getStorage, setStorage, getBrowserLang} from '@/utils'
import { findShearBeamCmq, findOneBeamAPI, findOneCorebuteAPI } from "../api";
const store = new Vuex.Store({
    state: {
        token: '',
        // userInfo: {} // 定义用户信息对象
        shearwallObj: {},
        beamObj: {},
        language: getStorage('lang') || getBrowserLang(), // 项目初始化时，默认为浏览器的语言
    }, // 存放数据

    getters: {
       language: state => state.language,
        getShearwall(state) {
            return state.shearwallObj;
        },
        getBeamObj(state) {
            return state.beamObj;
        }
    }, // 计算属性

    mutations: {
      setLanguage: (state, language) => {
        state.language = language;
        setStorage('lang', language);
      },
        // 更新 token 的 mutation 函数
        updateToken(state, newToken) {
            state.token = newToken
        },
        //更新数据
        updateShearwall(state, data) {
            state.shearwallObj = data;
            if (state.shearwallObj.seismic == "six") {
                state.shearwallObj.seismic2 = "6度";
            }
            if (state.shearwallObj.seismic == "seven") {
                state.shearwallObj.seismic2 = "7度(0.10g)";
            }
            if (state.shearwallObj.seismic == "seven_half") {
                state.shearwallObj.seismic2 = "7度(0.15g)";
            }
            if (state.shearwallObj.seismic == "eight") {
                state.shearwallObj.seismic2 = "8度(0.20g)";
            }
            if (state.shearwallObj.seismic == "eight_half") {
                state.shearwallObj.seismic2 = "8度(0.30g)";
            }
            if (state.shearwallObj.seismic == "nine") {
                state.shearwallObj.seismic2 = "9度";
            }
            if (state.shearwallObj.structure == "30") {
                state.shearwallObj.structure2 = "小于40米";
            }
            if (state.shearwallObj.structure == "50") {
                state.shearwallObj.structure2 = "40-60米";
            }
            if (state.shearwallObj.structure == "70") {
                state.shearwallObj.structure2 = "60-80米";
            }
            if (state.shearwallObj.structure == "90") {
                state.shearwallObj.structure2 = "80-100米";
            }
            if (state.shearwallObj.structure == "110") {
                state.shearwallObj.structure2 = "大于100米";
            }
            if (!state.shearwallObj.outPngUrl) {
                state.shearwallObj.state = "未设计";
            } else {
                state.shearwallObj.state = "已设计";
            }
            window.localStorage.setItem("shearwallObj", JSON.stringify(state.shearwallObj))
        },
        updateBeam(state, data) {
            state.beamObj = data;
            window.localStorage.setItem("beamObj", JSON.stringify(state.beamObj))
        },
        updateCore(state, data) {
            state.coreObj = data;
            if (state.coreObj.seismicIntensity == "0.05") {
                state.coreObj.seismicIntensity = "6度";
            }
            if (state.coreObj.seismicIntensity == "0.10") {
                state.coreObj.seismicIntensity = "7度（0.10g）";
            }
            if (state.coreObj.seismicIntensity == "0.15") {
                state.coreObj.seismicIntensity = "7度（0.15g）";
            }
            if (state.coreObj.seismicIntensity == "0.20") {
                state.coreObj.seismicIntensity = "8度（0.20g）";
            }

            if (state.coreObj.earthquakeGroup == "1") {
                state.coreObj.earthquakeGroup = "第一组";
            }
            if (state.coreObj.earthquakeGroup == "2") {
                state.coreObj.earthquakeGroup = "第二组";
            }
            if (state.coreObj.earthquakeGroup == "3") {
                state.coreObj.earthquakeGroup = "第三组";
            }
            if (state.coreObj.earthquakeGroup == "4") {
                state.coreObj.earthquakeGroup = "第四组";
            }

            if (state.coreObj.sceneCategory == "1.0") {
                state.coreObj.sceneCategory = "I0";
            }
            if (state.coreObj.sceneCategory == "1.1") {
                state.coreObj.sceneCategory = "I1";
            }
            if (state.coreObj.sceneCategory == "2") {
                state.coreObj.sceneCategory = "II";
            }
            if (state.coreObj.sceneCategory == "3") {
                state.coreObj.sceneCategory = "Ⅲ";
            }
            if (state.coreObj.sceneCategory == "4") {
                state.coreObj.sceneCategory = "Ⅳ";
            }
            
            window.localStorage.setItem("coreObj", JSON.stringify(state.coreObj))
        }
    }, // 修改state中数据的一些方法

    actions: {
        // 编辑剪力墙--回显数据
        async shearwallAction(context, Object) {
            const res = await findShearBeamCmq(Object.id)
            context.commit('updateShearwall', res.data)
        },
        async beamAction(context, Object) {
            const res = await findOneBeamAPI(Object.id)
            context.commit('updateBeam', res.data)
        },
        async coreAction(context, Object) {
            const res = await findOneCorebuteAPI(Object.id)
            context.commit('updateCore', res.data)
        }
        // 当点击时根据id查找对应数据
    }, // 异步方法

    modules: {

    }, // store模块
})
// 暴露实例
export default store  