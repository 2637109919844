import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
// swiper
// import "swiper/css/swiper.css"
// import 'swiper/dist/js/swiper'
// import 'swiper/dist/css/swiper.css'
// import Swiper from "swiper"
// elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {i18n: (key, value) => i18n.t(key, value) });
// video
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video=Video
// CAD
// import Mx from "mxdraw"
// 引入echarts
import * as echarts from 'echarts';
//vue全局注入echarts
Vue.prototype.$echarts = echarts;

//导入图片预览viewer组件以及css
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

import moment from 'moment'
Vue.prototype.$moment = moment
import store from './store';
import i18n from './lang';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
